/********** Add Your Global CSS Here **********/
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin-bottom: 0;
   font-family: 'Roboto', sans-serif;
  /* font-family: 'Brandon_reg'; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}
html{
  scroll-behavior: smooth;
}
.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

/********** Add Your Global RTL CSS Here **********/
/* Popover */
html[dir='rtl'] .ant-popover {
  text-align: right;
}
.ant-popover-content .cancel_btn, .ant-popover-content .delete_btn{
  width: 150px;
  height: 38px;
  padding: 0px;
  text-align: center;
  text-transform: uppercase;
}
.ant-popover-content .cancel_btn{
  margin-right: 15px;
  color:#00c0d5 !important;
  border-color:#00c0d5 !important;
  background-color:#ffffff !important;
}
/* Ecommerce Card */
html[dir='rtl'] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */
html[dir='rtl'] .has-success.has-feedback:after,
html[dir='rtl'] .has-warning.has-feedback:after,
html[dir='rtl'] .has-error.has-feedback:after,
html[dir='rtl'] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir='rtl'] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir='rtl'] .ant-modal-footer {
  text-align: left;
}

html[dir='rtl'] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir='rtl'] .ant-btn > .anticon + span,
html[dir='rtl'] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */
html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */
html[dir='rtl'] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */
html[dir='rtl'] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir='rtl'] .ant-popover-buttons {
  text-align: left;
}

/* Notification */
html[dir='rtl']
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir='rtl'] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir='rtl'] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */
html[dir='rtl'] .dz-hidden-input {
  display: none;
}
h1{
    font-size: 24px;
}
.hidden{
  display: none !important;
}
.ant-btn{
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}
input[type='file']{
  cursor: pointer;
}
html body{
  --antd-wave-shadow-color: transparent  !important;
}

.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item .ant-select-selected-icon{
  right: auto !important;
  left: 28px;
  border: 1px solid #00b9cd;
  border-radius: 3px;
  padding: 3px;
}
.ant-select-dropdown-menu{
  text-align: left;
}
.ant-select-dropdown-menu-item-selected .ant-select-selected-icon{
  color: #ffffff !important;
  background-color: #00b9cd !important;
}
.ant-select-dropdown-menu-item:hover .ant-select-selected-icon{
  color: #00b9cd !important;
}
.ant-select-dropdown.ant-select-dropdown--multiple .ant-select-dropdown-menu-item{
  padding-right: 10px !important;
  padding-left: 54px;
  font-size: 13px;
  color:#5a5a5a;
  background-color: transparent !important;
}
.ant-input{
  font-size: 14px !important;
}

.ant-modal-confirm-body .ant-modal-confirm-content{
  margin-top: 0px !important;
}
.ant-modal-wrap{
  z-index: 9999!important;
}
.ant-modal-body{
  text-align:center;
}
.ant-modal-wrap .ant-modal-body .ant-btn{
  vertical-align: top;
  max-width: 260px;
  width: 100%;
  padding: 0;
  height: 54px;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  background-color:#00B9CC;
  border-color: #00B9CC;
  margin-top: 30px;
}
.ant-modal-wrap .ant-modal-body .ant-btn:hover{
  background-color:#03afc1;
  border-color: #03afc1;
}
.ant-btn:hover{
  background-color:#03afc1;
  border-color: #03afc1;
}
.ant-select-dropdown,.ant-calendar-picker-container{
  z-index:999999 !important;
}
.ant-modal-mask{
  z-index: 99999 !important;
}
.ant-modal-wrap{
  z-index: 999999 !important;
}
.ant-popover-title{
  color:#888888 !important;
  text-align: center;
}
.ant-popover-inner-content .goals_percent_popover {
  text-align: center;
}
.ant-popover-inner-content .goals_percent_popover > span{
  font-size: 16px;
  color:#888888;
}
.ant-popover-inner-content .goals_percent_popover .goals_percentage_input{
  width: 43px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  color:#888888;
  padding: 2px 7px;
  margin-bottom: 6px;
  margin-top: 10px;
}
.ant-popover-inner-content .goals_percent_popover .popover_btn_wrapper{
  padding-top:5px;
  display: flex;
  justify-content: space-between;
}
.ant-popover-inner-content .goals_percent_popover .popover_btn_wrapper .ant-btn{
  width: 114px;
  padding: 0px;
  height: 32px;
  font-size: 500;
  border-radius: 4px;
  font-size: 14px;
  text-transform: uppercase;
}
.ant-popover-inner-content .goals_percent_popover .popover_btn_wrapper .percentage_cancel{
  background-color: #ffffff;
  color: #e02020;
  margin-right: 4px;
  color: #e02020;
  border-color:#e02020;
}
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
isoRecipentsImg{
  padding-bottom: 5px;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 20px;
}
.ant-modal-confirm-confirm{
  width:100% !important;
  max-width: 700px;
}
.ant-modal-confirm-confirm .ant-radio-wrapper{
  margin-top: 15px;
  font-size: 15px;
  margin-right:20px;
}
.anticon-question-circle{
  display: none !important;
}
.ant-modal-confirm-content{
  /*margin-left: 0px !important;*/
  text-align:left;
}
.ant-modal-confirm-confirm .ant-radio{
  width: 23px;
}
.ant-modal-confirm-confirm .ant-radio-inner{
  width: 23px !important;
  height: 23px !important;
  border-color:#00B9CC !important;
}
.ant-modal-confirm-confirm .ant-radio-inner:after{
  background-color:#00B9CC !important;
  width: 9px !important;
  height: 9px !important;
  top: 6px !important;
  left: 6px !important;
}
.ant-modal-confirm-confirm .ant-radio-checked .ant-radio-inner{
  border-color:#00B9CC !important;
}
.ant-modal-confirm-btns{
  width: 100%;
}
.ant-modal-confirm-btns .ant-btn:first-child{
  background-color: #ffffff !important;
  color: #00B9CC !important;
  border-color: #00B9CC !important;
}
.initials{
  color:#F1E0F4;
  background-color:#a56fc7;
  font-size: 18px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}
.ant-pagination{
  text-align: center;
}
.ant-spin{
  max-height: none !important;
}
.ant-rate-star.ant-rate-star-zero .ant-rate-star-first, .ant-rate-star.ant-rate-star-zero .ant-rate-star-second, .ant-rate-star.ant-rate-star-half .ant-rate-star-second{
  color: #888888;
}
/* .ant-rate-star.ant-rate-star-full .ant-rate-star-first, .ant-rate-star.ant-rate-star-full .ant-rate-star-second, .ant-rate-star.ant-rate-star-half .ant-rate-star-first{
  color: #eeae00;
} */
.ql-editor{
  user-select: auto !important;
  -moz-user-select: auto !important;
  -webkit-user-select: auto !important;
}
.address_popup_header span, .popup_header  span{
  font-size: 16px !important;
}
.img_wrapper img{
  width: 28px;
}
.back_btn{
  padding-left: 12px !important;
  transition: all .3s;
}
.back_btn:hover{
  background-position: right 46px center !important;
  background-size: 6px !important;
}
.ant-pagination-options-quick-jumper input:hover, .ant-pagination-options-quick-jumper input:focus{
  border-color:#a56fc7 !important;
  box-shadow:0 0 0 2px rgb(240, 222, 243) !important;
}
.ant-pagination .ant-pagination-item.ant-pagination-item-active:hover a{
  color: #ffffff !important;
}

.ql-snow .ql-tooltip{
  left: calc(100vh - 358px) !important;
}
@media only screen and (max-width: 667px) { 
  .hint_text{
    align-items: flex-start !important;
  }
  .application__header .ant-col{
    align-items: center !important;
  }
  .application__header h1{
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 480px) { 
  .back_btn{
    font-size: 16px !important;
  }
  .application__header .ant-col .img_wrapper{
    width: 100% !important;
    text-align: center !important;
  }
  .ant-row{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .ql-snow .ql-tooltip{
    left: 4px !important;
  }
}
@media only screen and (max-width: 360px) { 
  .ql-snow .ql-tooltip{
    left: 0px !important;
    padding: 5px 5px;
  }
  .ql-action:after{
    margin-left:6px;
  }
}