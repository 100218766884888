.backers {
  display: flex;
  align-content: center;
  max-width: 35em;
}

.backerWrapper {
  overflow: hidden;
}

.backerWrapper:last-child,
.backerWrapper:hover {
  overflow: visible;
}

.backerFirst {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  border: 1px solid #00cfff;
  color: white;
  font: normal normal 500 24px Roboto;
  text-align: center;
  padding: 24px 0;
  margin-bottom: 10px;
}

.backerN {
  z-index: 1;
  position: absolute;
  height: 75px;
  width: 75px;
  border-radius: 50%;
  transform: translate(-70%);
}
